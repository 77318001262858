'use client';

import { usePathname, useRouter } from 'next/navigation';
import { Fragment, useContext } from 'react';

import { InventoryForm } from '@/components/Inventory/InventoryForm';
import { InventoryFormContext } from '@/contexts/InventoryFormContext';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { getInventoryPageUrl } from '@lamesarv-sdk/tools';
import { InventoryField } from '@lamesarv-sdk/types';

export const InventoryMobileForm = () => {
  const { isMobileInquireOpen: isOpen, item, closeMobileInquire } = useContext(InventoryFormContext);
  const router = useRouter();
  const pathname = usePathname();

  const closeModal = () => {
    const inventoryRoute = getInventoryPageUrl(item, '');
    if (pathname !== inventoryRoute) router.push(inventoryRoute);
    closeMobileInquire();
  };

  return (
    <div className="md:hidden">
      <Transition show={isOpen} as={Fragment}>
        <Dialog className="relative z-50" onClose={closeModal}>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel className="pointer-events-auto w-screen">
                    <div className="flex h-full flex-col overflow-y-scroll bg-neutral-100 shadow-xl">
                      <div className="flex items-start justify-between px-4 py-3 border-b">
                        <DialogTitle className="text-lg font-medium uppercase">Vehicle Enquiry</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button type="button" className="relative focus:outline-none" onClick={closeModal}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faXmark} className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 pb-6" data-testid="mobile-form-container">
                        <div className="pb-5 border-b mb-5">
                          <span className="font-bold text-sm uppercase" data-testid="form-vehicle-name">
                            {item?.[InventoryField.title]}
                          </span>
                        </div>
                        {item && <InventoryForm onSuccess={closeMobileInquire} item={item} />}
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
