'use client';
import { Fragment, useContext, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { AutoComplete } from '@/components/AutoComplete/AutoComplete';
import { InventoryContext } from '@/contexts/InventoryContext';
import { getFavoritesChannel } from '@/tools/broadcast';
import { IMessageFavorites } from '@/types/inventory';
import { ILayoutHeader } from '@/types/layout';
import { faBars, faHeart, faMagnifyingGlass, faPhone, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { LaMesaLogo, LaMesaRecVanLogo, LinksList } from '@lamesarv-sdk/components';
import { ILink } from '@lamesarv-sdk/types';

interface IHeaderNavBarLinksProps {
  links: ILink[];
}

const HeaderNavBarLinks = (props: IHeaderNavBarLinksProps) => {
  const [locationPath, setLocationPath] = useState<string>('');

  useEffect(() => {
    setLocationPath(window.location.pathname);
  }, []);

  return props.links ? (
    <div className="flex flex-row gap-2 text-center justify-center text-md text-white">
      <LinksList
        links={props.links}
        classList="gap-4"
        classListItem="text-[13px] uppercase"
        classListItemActive="underline underline-offset-4"
        getActive={(link) => locationPath === link.url}
      />
    </div>
  ) : null;
};

interface IHeaderContactBlockProps {
  title?: string;
  text?: string;
  url?: string;
}

const HeaderContactBlock = (props: IHeaderContactBlockProps) => {
  return (
    <a className="flex flex-col items-start text-left text-white uppercase px-4 last:pr-2" href={props.url}>
      <div className="text-xs">{props.title}</div>
      <div className="text-base font-bold flex flex-row items-center gap-1">
        <FontAwesomeIcon icon={faPhone} className="h-4" />
        <span>{props.text}</span>
      </div>
    </a>
  );
};

interface IHeaderAutoCompleteProps {
  placeholder?: string;
  className?: string;
}

const HeaderAutoComplete = ({ className, placeholder }: IHeaderAutoCompleteProps) => {
  return (
    <AutoComplete
      className={twMerge(
        'w-64 min-[1040px]:w-64 xl:w-44 2xl:w-96 bg-black [&_input:focus]:border-white [&_input]:text-white [&_input]:bg-black [&_input]:text-sm [&_input]:leading-[0.875rem] [&_input]:placeholder-white [&_input]:border-white [&_input]:!rounded-none',
        className,
      )}
      placeholder={placeholder}
    />
  );
};

const HeaderInventoryFavorites = () => {
  const { context } = useContext(InventoryContext);
  const [itemsQuantity, setItemsQuantity] = useState<number>(0);

  useEffect(() => {
    const channel = getFavoritesChannel();

    channel.onmessage = (message: IMessageFavorites) => {
      setItemsQuantity(message.quantity ?? 0);
    };

    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    if (context?.inventoryFavorites !== undefined) {
      setItemsQuantity(context?.inventoryFavorites?.length);
    }
  }, [context?.inventoryFavorites]);

  return (
    <a className="relative cursor-pointer" href="/favorites" data-testid="header-favorite-btn">
      <FontAwesomeIcon icon={faHeart} className="flex h-6 text-white" />
      {itemsQuantity > 0 && (
        <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full flex items-center justify-center">
          <span className="text-xs text-white">{itemsQuantity}</span>
        </span>
      )}
    </a>
  );
};

export const LayoutHeader = (props: ILayoutHeader) => {
  const [isSearchMobileOpen, setIsSearchMobileOpen] = useState(false);
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);

  return (
    <Fragment>
      <div className="flex flex-row justify-between py-2 px-4 bg-neutral-900 relative z-30">
        {/* Mobile */}
        <div className="flex-1 flex items-center gap-5 min-[860px]:hidden">
          <FontAwesomeIcon icon={faBars} className="h-5 text-white" onClick={() => setIsMenuMobileOpen(true)} />
          <a href={(props.contact[0] as { url: string }).url}>
            <FontAwesomeIcon icon={faPhone} className="h-5 text-white" />
          </a>
        </div>
        {/* Mobile */}
        <a href="/" className="block min-[860px]:hidden">
          <LaMesaRecVanLogo className="h-10 2xs:h-12 fill-white" />
        </a>
        {/* Mobile */}
        <div className="flex-1 flex items-center gap-5 justify-end min-[860px]:hidden">
          <HeaderInventoryFavorites />
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            onClick={() => setIsSearchMobileOpen((prev) => !prev)}
            className="h-5 text-white"
          />
        </div>
        {/* Desktop */}
        <div className="hidden min-[860px]:flex flex-col min-[1040px]:flex-row gap-4 min-[1040px]:gap-5 xl:gap-8 2xl:gap-20 items-start min-[1040px]:items-center">
          <a href="/" className="block">
            <LaMesaRecVanLogo className="h-14 fill-white" />
          </a>
          <HeaderNavBarLinks links={props.navBarLinks} />
        </div>
        {/* Desktop */}
        <div className="hidden min-[860px]:flex flex-col my-3 xl:my-0 gap-4 xl:flex-row items-center xl:gap-2">
          <div className="flex flex-row gap-3 items-center">
            <HeaderAutoComplete placeholder={props.searchPlaceholder} />
            <HeaderInventoryFavorites />
          </div>
          <div className="flex flex-row items-center divide-x">
            {props.contact?.map((contact, contactIndex) => {
              return <HeaderContactBlock key={contact.title + contactIndex} {...contact} />;
            })}
          </div>
        </div>
      </div>
      {/* Mobile */}
      <Transition
        show={isSearchMobileOpen}
        as="div"
        className="relative min-[860px]:hidden bg-neutral-900 px-4 flex justify-end items-center z-30"
        enter="transition-all ease-in-out duration-200"
        enterFrom="-translate-y-full h-0 overflow-hidden -z-10"
        enterTo="translate-y-0 h-12 overflow-visible z-10"
        leave="transition-all ease-in-out duration-200"
        leaveFrom="translate-y-0 h-12 overflow-visible z-10"
        leaveTo="-translate-y-full h-0 overflow-hidden -z-10"
      >
        <HeaderAutoComplete
          placeholder={props.searchPlaceholder}
          className="[&>div>div]:max-w-[calc(100vw-32px)] z-10"
        />
      </Transition>
      {/* Mobile */}
      <Transition show={isMenuMobileOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 min-[860px]:hidden"
          onClose={() => setIsMenuMobileOpen(false)}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              type="button"
              className="fixed inset-0 bg-neutral-600 bg-opacity-75"
              onClick={() => setIsMenuMobileOpen(false)}
            />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col pr-12 bg-neutral-900">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-1 pt-2">
                  <button
                    type="button"
                    className={
                      'flex items-center justify-center h-10 w-10 rounded-full focus:outline-none cursor-pointer'
                    }
                    onClick={() => setIsMenuMobileOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <FontAwesomeIcon icon={faXmark} className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex px-4 pt-4 pb-2">
                <a href="/" className="outline-none">
                  <LaMesaLogo className="h-6 fill-white" />
                </a>
              </div>
              <div className="flex-1 py-2 overflow-y-auto">
                <ul className="flex flex-col">
                  {props.sidebarLinks.map((item, index) => (
                    <li key={item.title + index}>
                      <a className="block py-3 px-4 text-neutral-300 focus:outline-none" href={item.url}>
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </Fragment>
  );
};
